<template>
  <div id="app">
    <!--主体-->
    <mm-header />
    <router-view />
    <!--更新说明-->
    <mm-dialog ref="versionDialog" type="alert" head-text="更新提示" :body-text="versionInfo" />
    <!--公告-->
    <mm-dialog
      ref="noticeDialog"
      head-text="公告"
      :body-text="notice"
      confirm-btn-text="作者 Blog"
      cancel-btn-text="关闭"
      @confirm="openPage('https://notes.fe-mm.com')"
    />
    <!--播放器-->
    <audio ref="mmPlayer"></audio>
  </div>
</template>

<script>
import { mapMutations, mapActions } from 'vuex'
import { getPlaylistDetail } from 'api'
import { MMPLAYER_CONFIG, VERSION } from '@/config'
import MmHeader from 'components/mm-header/mm-header'
import MmDialog from 'base/mm-dialog/mm-dialog'
import { getVersion, setVersion } from '@/utils/storage'

const VERSION_INFO = `<div class="mm-dialog-text text-left">
版本号：${VERSION}（${process.env.VUE_APP_UPDATE_TIME}）<br/>
1、 采用新版图标<br>
2、 修复音乐搜索<br>
3、 优化滚动条样式
</div>`

export default {
  name: 'App',
  components: {
    MmHeader,
    MmDialog,
  },
  created() {
    // 设置版本更新信息
    this.versionInfo = VERSION_INFO
    this.notice = `<div class="mm-dialog-text text-left">
收到网易云音乐法务告知函（本站非法攫取了网易云音乐的用户流量构成不正当竞争），以后不再提供音乐在线播放<br><br>

也不知道是哪个老六给我宣传了，简直捧杀（😒 你出来，保证不打你）<br><br>
😭 本来还有新版本，现在只能说：江湖路远，有缘再见
</div>`

    // 获取正在播放列表
    getPlaylistDetail(MMPLAYER_CONFIG.PLAYLIST_ID).then((playlist) => {
      const list = playlist.tracks.slice(0, 100)
      this.setPlaylist({ list })
    })

    // 设置title
    let OriginTitile = document.title
    let titleTime
    document.addEventListener('visibilitychange', function () {
      if (document.hidden) {
        document.title = '死鬼去哪里了！'
        clearTimeout(titleTime)
      } else {
        document.title = '(つェ⊂)咦!又好了!'
        titleTime = setTimeout(function () {
          document.title = OriginTitile
        }, 2000)
      }
    })

    // 设置audio元素
    this.$nextTick(() => {
      this.setAudioele(this.$refs.mmPlayer)
    })

    // 首次加载完成后移除动画
    let loadDOM = document.querySelector('#appLoading')
    if (loadDOM) {
      const animationendFunc = function () {
        loadDOM.removeEventListener('animationend', animationendFunc)
        loadDOM.removeEventListener('webkitAnimationEnd', animationendFunc)
        document.body.removeChild(loadDOM)
        loadDOM = null

        this.$refs.noticeDialog.show()
      }.bind(this)
      loadDOM.addEventListener('animationend', animationendFunc)
      loadDOM.addEventListener('webkitAnimationEnd', animationendFunc)
      loadDOM.classList.add('removeAnimate')
    }
  },
  methods: {
    openVersionDialog() {
      const version = getVersion()
      if (version !== null) {
        setVersion(VERSION)
        if (version !== VERSION) {
          this.$refs.versionDialog.show()
        }
      } else {
        setVersion(VERSION)
        this.$refs.versionDialog.show()
      }
    },
    openPage(url) {
      window.open(url)
    },
    ...mapMutations({
      setAudioele: 'SET_AUDIOELE',
    }),
    ...mapActions(['setPlaylist']),
  },
}
</script>

<style lang="less">
#app {
  position: relative;
  width: 100%;
  height: 100%;
  color: @text_color;
  font-size: @font_size_medium;

  audio {
    position: fixed;
  }
}
</style>
